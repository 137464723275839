<template>
    <div>
        <div class="container py-5 my-0 my-sm-5 px-4">
            <div class="row mb-2">
                <div class="col-12">
                    <div class="row align-items-center mb-0 pb-5">
                        <div class="col-6 col-sm-6 col-md-6 text-left">
                            <div class="logo-wrapper"><img src="@/assets/img/bau-logo-for-light.svg"/></div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 text-right">
                            <h4 class="mb-0">{{ $t('e_registration_system') }}</h4>
                            <b-button @click="logout" size="sm" class="float-right mt-2">
	                            <i class="ri-logout-box-r-line"></i>
	                            &nbsp;
	                            {{ $t('logout').toUpper() }}
                            </b-button>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="border px-5 pt-5 pb-3 mb-1 rounded">
                        <div class="border px-5 pt-5 pb-2 mb-1 rounded">
                            <div class="row">
                                <div class="col-12 mb-2">
                                    <h5 class="m-0">
                                        {{ stateData.name }} {{ stateData.surname }}
                                    </h5>
                                </div>
                                <div class="col-12 col-md-4 col-xl-3 mb-3 ellipsis-1">
                                    <label class="col-form-label mr-1 p-0">{{ $t('student_number') }}:</label>
                                    {{ stateData.student_number }}
                                </div>
                                <div class="col-12 col-md-4 col-xl-3 mb-3 ellipsis-1">
                                    <label class="col-form-label mr-1 p-0">{{ $t('osym_scholarship') }}:</label>
                                    %{{ stateData.scholarship_rate }}
                                </div>
                                <div class="col-12 col-md-4 col-xl-3 mb-3 ellipsis-1">
                                    <b-badge :variant="stateData.payment_status == 'E' ? 'success' : 'danger'">
                                        {{ $t('payment_approval') }}
                                        {{
                                            $i18n.locale == 'tr' ? stateData.payment_status_text : stateData.payment_status_text_en
                                        }}
                                    </b-badge>
                                </div>
                                <div class="col-12 col-xl-6 mb-3">
                                    <label class="col-form-label mr-1 p-0">{{ $t('faculty') }}:</label>
                                    {{ $i18n.locale == 'tr' ? stateData.faculty_name : stateData.faculty_name_en }}
                                </div>
                                <div class="col-12 col-xl-6 mb-3">
                                    <label class="col-form-label mr-1 p-0">{{ $t('program') }}:</label>
                                    {{ $i18n.locale == 'tr' ? stateData.program_name : stateData.program_name_en }}
                                </div>

                            </div>
                        </div>
                        <b-tabs content-class="py-5 position-relative" class="mt-5" v-model="tabIndex">
                            <div class="tabs-bottom-line"></div>
                            <b-tab active>
                                <template #title><i class="ri-profile-line top-plus-2 mr-2"></i>{{ $t('identity') }}
                                </template>
                                <identity-information :stateData="stateData"></identity-information>
                            </b-tab>
                            <b-tab>
                                <template #title><i class="ri-map-pin-line top-plus-2 mr-2"></i>{{ $t('address') }}
                                </template>
                                <Address :stateData="stateData" :formData="contactForm" :tab="tabIndex"></Address>
                                <div class="mb-2 mt-4">
                                    <h6 class="text-uppercase mb-0">
	                                    {{ $t('contact_address_information').toUpper() }}
                                    </h6>
                                </div>
                                <ValidationObserver ref="contactForm">
                                    <div class="row">
                                        <div class="col-6 col-md-4">
                                            <ValidationProvider name="city" rules="required" v-slot="{valid, errors}">
                                                <b-form-group>
                                                    <label>{{ $t('city') }} *</label>
                                                    <b-form-select
                                                        ref="city"
                                                        v-model="contactForm.city"
                                                        :options="cities"></b-form-select>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-6 col-md-4">
                                            <ValidationProvider name="district" rules="required"
                                                                v-slot="{valid, errors}">
                                                <b-form-group>
                                                    <label>{{ $t('district') }} *</label>
                                                    <district-outline-selectbox ref="district"
                                                                                :validateError="errors[0]"
                                                                                :city_id="contactForm.city"
                                                                                v-model="contactForm.district"></district-outline-selectbox>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-10 col-md-8">
                                            <ValidationProvider name="address" rules="required"
                                                                v-slot="{valid, errors}">
                                                <b-form-group>
                                                    <label>{{ $t('address') }} *</label>
                                                    <b-form-input
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        v-model="contactForm.address"></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </ValidationObserver>

                            </b-tab>
                            <b-tab>
                                <template #title><i class="ri-team-line top-plus-2 mr-2"></i>{{ $t('family_info') }}
                                </template>

                                <div class="mb-2">
                                    <h6 class="text-uppercase mb-0">
	                                    {{ $t('family_info').toUpper() }}
                                    </h6>
                                </div>
                                <ValidationObserver ref="familyForm">
                                    <div class="row">
                                        <div class="col-12 col-sm-4 col-md-4">
                                            <ValidationProvider name="mothersJob" rules="" vid="mothersJob" v-slot="{valid, errors}">
                                                <b-form-group>
                                                    <label>{{ $t('mothers_job') }}</label>
                                                    <b-form-select
                                                        v-model="familyForm.mothersJob"
                                                        :options="jobs"></b-form-select>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>

                                        <div class="col-12 col-sm-4 col-md-4" v-if="familyForm.mothersJob == 'Diğer'">
                                            <ValidationProvider name="mothersJobOther" rules="" v-slot="{valid, errors}">
                                                <b-form-group>
                                                    <label>{{ $t('job_other') }}</label>
                                                    <b-form-input
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        v-model="familyForm.mothersJobOther"></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>

                                        <div class="col-12 col-sm-4 col-md-4">
                                            <ValidationProvider name="mothersGsm" rules="" v-slot="{valid, errors}">
                                                <b-form-group>
                                                    <label>{{ $t('mothers_gsm') }}</label>
                                                    <b-form-input
                                                        v-mask="'(5##) ###-####'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        v-model="familyForm.mothersGsm"></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                        </div>
                                    <div class="row">
                                        <div class="col-12 col-sm-4 col-md-4">
                                            <ValidationProvider name="fathersJob" rules="" vid="" v-slot="{valid, errors}">
                                                <b-form-group>
                                                    <label>{{ $t('fathers_job') }}</label>
                                                    <b-form-select
                                                        v-model="familyForm.fathersJob"
                                                        :options="jobs"></b-form-select>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>

                                        <div class="col-12 col-sm-4 col-md-4" v-if="familyForm.fathersJob == 'Diğer'">
                                            <ValidationProvider name="fathersJobOther" rules="" v-slot="{valid, errors}">
                                                <b-form-group>
                                                    <label>{{ $t('job_other') }}</label>
                                                    <b-form-input
                                                        v-uppercase
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        v-model="familyForm.fathersJobOther"></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>

                                        <div class="col-12 col-sm-4 col-md-4">
                                            <ValidationProvider name="fathersGsm" rules="" v-slot="{valid, errors}">
                                                <b-form-group>
                                                    <label>{{ $t('fathers_gsm') }}</label>
                                                    <b-form-input
                                                        v-mask="'(5##) ###-####'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        v-model="familyForm.fathersGsm"></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>

                                    </div>
                                </ValidationObserver>

                            </b-tab>
                            <b-tab>
                                <template #title><i class="ri-phone-line top-plus-2 mr-2"></i>{{ $t('contact') }}
                                </template>
                                <Communication ref="communication" :stateData="stateData" :errStateMobileVerify="errStateMobileVerify" 
                                    :errStateEmailVerify="errStateEmailVerify" :tab="3"></Communication>
                                <div class="mb-2 mt-4">
                                    <h6 class="text-uppercase mb-0">
	                                    {{ $t('emergency_contact_txt').toUpper() }}
                                    </h6>
                                </div>

                                <ValidationObserver ref="emergencyForm">
                                    <div class="row">
                                        <div class="col-6 col-md-4">
                                            <ValidationProvider name="degreeOfProximity"
                                                                v-slot="{valid, errors}">
                                                <b-form-group>
                                                    <label>{{ $t('degree_of_proximity') }}</label>
                                                    <b-form-input
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        v-mask="upperAlphaMask"
                                                        v-model="emergencyForm.degreeOfProximity"></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                        v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-6 col-md-4">
                                            <ValidationProvider name="nameSurname" 
                                                                v-slot="{valid, errors}">
                                                <b-form-group>
                                                    <label>{{ $t('name_surname') }}</label>
                                                    <b-form-input
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        v-mask="upperAlphaMask"
                                                        v-model="emergencyForm.nameSurname"></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                        v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-4">
                                            <ValidationProvider name="gsmNo"  v-slot="{valid, errors}">
                                                <b-form-group>
                                                    <label>{{ $t('mobile_number') }}</label>
                                                    <b-form-input
                                                        v-mask="'(5##) ###-####'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        v-model="emergencyForm.gsmNo"></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                    v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </ValidationObserver>
                            </b-tab>
                            <b-tab>
                                <template #title><i class="ri-pulse-line top-plus-2 mr-2"></i>{{ $t('health') }}
                                </template>
                                <ValidationObserver ref="healthForm">
                                    <div class="row">

                                        <div class="col-12">
                                            <ValidationProvider name="surgeryOrDiscomfort" rules=""
                                                                v-slot="{valid, errors}">
                                                <b-form-group :label="$t('surgery_or_discomfort')">
                                                    <b-form-input
                                                        v-uppercase
                                                        :placeholder="$t('none')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        v-model="healthForm.surgeryOrDiscomfort"></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-12 col-lg-4">
                                            <ValidationProvider name="usedDrugs" rules="" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('used_drugs')">
                                                    <b-form-input
                                                        v-uppercase
                                                        :placeholder="$t('none')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        v-model="healthForm.usedDrugs"></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-6 col-lg-4">
                                            <ValidationProvider name="disabilityType" rules="" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('disability_type')">
                                                    <b-form-select
                                                        v-model="healthForm.disabilityType"
                                                        :options="disabledOptions"></b-form-select>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-6 col-lg-4">
                                            <ValidationProvider name="disabilityRate" :rules="healthForm.disabilityType != 0 ? 'required' : ''" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('disability_rate')">
                                                    <b-form-select
                                                        v-model="healthForm.disabilityRate">
                                                        <option value="">{{ $t('select') }}</option>
                                                        <option value="0">0</option>
                                                        <option v-for="(row, rowindex) in 20" :key="rowindex"
                                                                :value="row*5">{{ row * 5 }}
                                                        </option>
                                                    </b-form-select>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </ValidationObserver>
                            </b-tab>
                            <b-tab>
                                <template #title><i class="ri-building-line top-plus-2 mr-2"></i>ÖSYM</template>
                                <ValidationObserver ref="osymForm">
                                    <div class="row">
                                        <div class="col-12 col-md-6">
                                            <ValidationProvider name="osymDocCode" rules="required"
                                                                v-slot="{valid, errors}">
                                                <b-form-group>
                                                    <label>{{ $t('osymDocCode') }} *</label>
                                                    <b-form-input
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        v-mask="upperAlphaNumMask"
                                                        v-model="osymForm.osymDocCode"></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <ValidationProvider name="osymExamCode" rules="required"
                                                                v-slot="{valid, errors}">
                                                <b-form-group>
                                                    <label>{{ $t('osymExamCode') }} *</label>
                                                    <b-form-input
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        v-mask="upperAlphaNumMask"
                                                        v-model="osymForm.osymExamCode"></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-6 col-md-4">
                                            <b-form-group :label="$t('program_code')">{{
                                                    stateData.osym_code
                                                }}
                                            </b-form-group>
                                        </div>
                                        <div class="col-6 col-md-4">
                                            <b-form-group :label="$t('placement_score')">{{
                                                    stateData.placement_score
                                                }}
                                            </b-form-group>
                                        </div>
                                        <div class="col-6 col-md-4">
                                            <b-form-group :label="$t('placement_rank')">{{
                                                    stateData.placement_rank
                                                }}
                                            </b-form-group>
                                        </div>
                                    </div>
                                </ValidationObserver>
                            </b-tab>
                            <b-tab>
                                <template #title><i class="ri-building-line top-plus-2 mr-2"></i>{{$t('requests_info')}}</template>
                                <ValidationObserver ref="requestsForm">
                                    <div class="row">
                                        <div class="col-12">
                                            <ValidationProvider name="wantLeave" rules="" v-slot="{valid, errors}">
                                            <b-form-group v-slot="{ ariaDescribedby }">
                                                    <label>{{ $t('want_leave_of_absence') }} *</label>
                                                    <b-form-radio-group v-model="requestsForm.wantLeave"
                                                                        :aria-describedby="ariaDescribedby">
                                                        <b-form-radio value="E">{{ $t('yes') }}</b-form-radio>
                                                        <b-form-radio value="H">{{ $t('no') }}</b-form-radio>
                                                    </b-form-radio-group>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <p v-if="requestsForm.wantLeave=='E'"> {{ $t('e_registration_system_info') }} </p>
                                            <div class="row" v-if="requestsForm.wantLeave=='E'">
                                                <div class="col-12 col-sm-6 col-md-6">
                                                    <div class="col-12">
                                                        <ValidationProvider name="leaveOfAbsenceReason" :rules="requestsForm.wantLeave == 'E' ? 'required' : ''" v-slot="{ errors}">
                                                        <b-form-group>
                                                            <label>{{ $t('leave_of_absence_description') }} *</label>
                                                           
                                                            <b-form-select
                                                                v-model="requestsForm.reason"
                                                                :options="leaveOfAbsenceReasonOptions"></b-form-select>
                                                            <b-form-invalid-feedback v-if="errors[0]"
                                                                                     v-html="errors[0]"></b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                    </div>
                                                    <div class="col-12" v-if="requestsForm.reason === 'other'">
                                                        <b-form-group>
                                                            <label>{{ $t('leave_of_absence_description') }} *</label>
                                                            <b-form-input
                                                                v-model="requestsForm.explanation_for_leave_of_absence"></b-form-input>
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-6">
                                                    <ValidationProvider name="leaveOfAbsenceDuration" :rules="requestsForm.wantLeave == 'E' ? 'required' : ''" v-slot="{valid, errors}">
                                                        <b-form-group>
                                                            <label>{{ $t('leave_of_absence_semesters') }} *</label>
                                                            <b-form-select
                                                                v-model="requestsForm.duration"
                                                                :options="leaveOfAbsenceDurationOptions"></b-form-select>
                                                            <b-form-invalid-feedback v-if="errors[0]"
                                                                                     v-html="errors[0]"></b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12" v-if="stateData.program_language=='TR'">
                                            <ValidationProvider name="want_optional_prep" rules="" v-slot="{valid, errors}">
                                            <b-form-group v-slot="{ ariaDescribedby }">
                                                <label>{{ $t('want_optional_prep') }} *</label>
                                                <b-form-radio-group v-model="stateData.want_optional_prep"
                                                                    :aria-describedby="ariaDescribedby">
                                                    <b-form-radio value="E">{{ $t('yes') }}</b-form-radio>
                                                    <b-form-radio value="H">{{ $t('no') }}</b-form-radio>
                                                </b-form-radio-group>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                            </ValidationProvider>
                                            <p v-if="stateData.want_optional_prep=='E'">* Öğretim dili Türkçe olan programlarda
                                                isteğe bağlı hazırlık sınıfı eğitimi almak isteyen öğrencilerin bursları
                                                hazırlık sınıfı için geçerlidir. Ancak hazırlık sınıfı bitiminde ilgili fakülte
                                                ve bölümden kaydını sildiren öğrenciler hazırlık okulu ücretini Yabancı Diller
                                                Hazırlık Okulu ücreti üzerinden ödemekle yükümlüdür.</p>
                                        </div>
                                        <div class="col-12" v-if="stateData.program_language=='EN'">
                                            <ValidationProvider name="hasEnglish" rules="" v-slot="{ errors}">
                                            <b-form-group v-slot="{ ariaDescribedby }">
                                                <label>{{ $t('do_you_have_language_proficiency') }} *</label>
                                                <b-form-radio-group v-model="requestsForm.hasEnglish"
                                                                    :aria-describedby="ariaDescribedby">
                                                    <b-form-radio value="E">{{ $t('yes') }}</b-form-radio>
                                                    <b-form-radio value="H">{{ $t('no') }}</b-form-radio>
                                                </b-form-radio-group>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                            </ValidationProvider>
                                            <p v-if="requestsForm.hasEnglish=='E'">* Dil yeterlilik
                                                belgesini Belgeler sekmesinden yükleyiniz. EŞ değerliği kabul edenler sınavlar
                                                için <a href="javascript:void(0)" @click="openLanguageExamsModal">tıklayınız</a>
                                            </p>
                                            <div class="row" v-if="requestsForm.hasEnglish=='E'">
                                                <div class="col-12 col-sm-4 col-md-4">
                                                    <ValidationProvider name="englishCertType" vid="englishCertType" :rules="requestsForm.hasEnglish == 'E' ? 'required' : ''" v-slot="{valid, errors}">
                                                        <b-form-group>
                                                            <label>{{ $t('cert_type') }} *</label>
                                                            <b-form-select
                                                                v-model="requestsForm.certificate"
                                                                :options="certificateOptions"></b-form-select>
                                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-12 col-sm-4 col-md-4">
                                                    <ValidationProvider name="english_proficiency_exam_date" :rules="requestsForm.hasEnglish == 'E' ? 'required' : ''" v-slot="{valid, errors}">
                                                        <b-form-group>
                                                            <label>{{ $t('exam_date') }} *</label>
                                                            <b-form-input
                                                                type="date"
                                                                v-model="requestsForm.english_proficiency_exam_date">
                                                            </b-form-input>
                                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-12 col-sm-4 col-md-4">
                                                    <ValidationProvider name="english_proficiency_exam_score" :rules="requestsForm.hasEnglish == 'E' ? 'required' : ''" v-slot="{valid, errors}">
                                                        <b-form-group>
                                                            <label>{{ $t('exam_score') }} *</label>
                                                            <b-form-input
                                                                type="number"
                                                                v-model="requestsForm.english_proficiency_exam_score">
                                                            </b-form-input>
                                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-12 col-sm-4 col-md-4" v-if="this.requestsForm.certificate=='toefl'">
                                                    <ValidationProvider name="toeflUsername" rules="required_if:englishCertType,toefl" v-slot="{valid, errors}">
                                                        <b-form-group>
                                                            <label>{{ $t('toefl_username') }} *</label>
                                                            <b-form-input
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                v-model="requestsForm.toeflUsername"></b-form-input>
                                                            <b-form-invalid-feedback v-if="errors[0]"
                                                                                     v-html="errors[0]"></b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-12 col-sm-4 col-md-4" v-if="this.requestsForm.certificate=='toefl'">
                                                    <ValidationProvider name="toeflPassword" rules="required_if:englishCertType,toefl" v-slot="{valid, errors}">
                                                        <b-form-group>
                                                            <label>{{ $t('toefl_password') }} *</label>
                                                            <b-form-input
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                v-model="requestsForm.toeflPassword"></b-form-input>
                                                            <b-form-invalid-feedback v-if="errors[0]"
                                                                                     v-html="errors[0]"></b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ValidationObserver>
                            </b-tab>
                            <b-tab>
                                <template #title><i class="ri-file-line top-plus-2 mr-2"></i>{{ $t('documents') }}
                                </template>
                                <documents ref="documents" :stateData.sync="stateData"></documents>
                            </b-tab>
                            <b-tab>
                                <template #title><i class="ri-file-list-2-line top-plus-2 mr-2"></i>{{ $t('summary') }}
                                </template>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="mb-2">
                                            <h6 class="mb-0">{{ $t('student_information').toUpper() }}</h6>
                                        </div>
                                        <div class="border px-5 pt-5 pb-2 mb-4 rounded">
                                            <div class="row">
                                                <div class="col-6 col-xl-3">
                                                    <b-form-group :label="$t('student_number')">
                                                        {{ stateData.student_number ? stateData.student_number : '-' }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-6 col-xl-3">
                                                    <b-form-group :label="$t('name_surname')">
                                                        {{ stateData.name ? stateData.name : '-' }}
                                                        {{ stateData.surname ? stateData.surname : '-' }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                                                    <b-form-group :label="$t('faculty')">
                                                        {{
                                                            $i18n.locale == 'tr' ? stateData.faculty_name : stateData.faculty_name_en
                                                        }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                                                    <b-form-group :label="$t('program')">
                                                        {{
                                                            $i18n.locale == 'tr' ? stateData.program_name : stateData.program_name_en
                                                        }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                                                    <b-form-group :label="$t('osym_scholarship')">
                                                        {{
                                                            stateData.scholarship_rate ? '% ' + stateData.scholarship_rate : '-'
                                                        }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                                                    <b-form-group :label="$t('want_leave_of_absence')">
                                                        {{ stateData.want_leave_of_absence === 'H' ? this.$t('no') : '' }}
                                                        {{ stateData.want_leave_of_absence === 'E' ? this.$t('yes') : '' }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3"
                                                     v-if="stateData.program_language=='EN'">
                                                    <b-form-group :label="$t('do_you_have_language_proficiency')">
                                                        {{ stateData.has_english_proficiency_certificate === 'H' ? this.$t('no') : '' }}
                                                        {{ stateData.has_english_proficiency_certificate === 'E' ? this.$t('yes') : '' }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3"
                                                     v-if="stateData.program_language=='TR'">
                                                    <b-form-group :label="$t('want_optional_prep')">
                                                        {{ stateData.want_optional_prep === 'H' ? this.$t('no') : '' }}
                                                        {{ stateData.want_optional_prep === 'E' ? this.$t('yes') : '' }}
                                                    </b-form-group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="mb-2">
                                            <h6 class="text-uppercase mb-0">
	                                            {{ $t('identity_information').toUpper() }}
                                            </h6>
                                        </div>
                                        <div class="border px-5 pt-5 pb-2 mb-4 rounded">
                                            <identity-information :stateData="stateData"></identity-information>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="mb-2">
                                            <h6 class="text-uppercase mb-0">
	                                            {{ $t('address_information').toUpper() }}
                                            </h6>
                                        </div>
                                        <div class="border px-5 pt-5 pb-2 mb-4 rounded">
                                            <Address :stateData="stateData" :formData="contactForm"
                                                     :tab="tabIndex"></Address>

                                            <div class="mb-2 mt-4">
                                                <h6 class="text-uppercase mb-0">
	                                                {{ $t('contact_address_information').toUpper() }}
                                                </h6>
                                            </div>
                                            <div class="row">
                                                <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                                                    <b-form-group :label="$t('city')">
                                                        {{ getSelectText(this.$refs.city) }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                                                    <b-form-group :label="$t('district')">
                                                        {{ getSelectText(this.$refs.district) }}
                                                    </b-form-group>
                                                </div>

                                                <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                                                    <b-form-group :label="$t('address')">
                                                        {{ contactForm.address }}
                                                    </b-form-group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="mb-2">
                                            <h6 class="text-uppercase mb-0">
                                                {{ $t('family_info').toUpper()  }}</h6>
                                        </div>
                                        <div class="border px-5 pt-5 pb-2 mb-4 rounded">
                                            <div class="row">
                                                <div class="col-6">
                                                    <b-form-group :label="$t('mothers_job')">
                                                        {{
                                                            familyForm.mothersJob ? (familyForm.mothersJob=='Diğer'?familyForm.mothersJobOther:familyForm.mothersJob) : '-'
                                                        }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-6">
                                                    <b-form-group :label="$t('mothers_gsm')">
                                                        {{ familyForm.mothersGsm ? familyForm.mothersGsm : '-' }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-6">
                                                    <b-form-group :label="$t('fathers_job')">
                                                        {{
                                                            familyForm.fathersJob ? (familyForm.fathersJob=='Diğer'?familyForm.fathersJobOther:familyForm.fathersJob) : '-'
                                                        }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-6">
                                                    <b-form-group :label="$t('fathers_gsm')">
                                                        {{
                                                            familyForm.fathersGsm ? familyForm.fathersGsm : '-'
                                                        }}
                                                    </b-form-group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="mb-2">
                                            <h6 class="text-uppercase mb-0">
	                                            {{ $t('contact_information').toUpper() }}
                                            </h6>
                                        </div>
                                        <div class="border px-5 pt-5 pb-2 mb-4 rounded">
                                            <communication :stateData="stateData"></communication>
                                            <div class="row">
                                                <div class="col-12 mb-3">
                                                    <h6 class="mb-0">{{ $t('emergency_contact_txt') }}</h6>
                                                </div>
                                                <div class="col-6 col-md-4">
                                                    <b-form-group :label="$t('degree_of_proximity')">
                                                        {{
                                                            emergencyForm.degreeOfProximity ? emergencyForm.degreeOfProximity : '-'
                                                        }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-6 col-md-4">
                                                    <b-form-group :label="$t('name_surname')">
                                                        {{ emergencyForm.nameSurname ? emergencyForm.nameSurname : '-' }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-4">
                                                    <b-form-group :label="$t('mobile_number')">
                                                        {{ emergencyForm.gsmNo ? emergencyForm.gsmNo : '-' }}
                                                    </b-form-group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="mb-2">
                                            <h6 class="text-uppercase mb-0">
	                                            {{ $t('health_information').toUpper() }}
                                            </h6>
                                        </div>
                                        <div class="border px-5 pt-5 pb-2 mb-4 rounded">
                                            <div class="row">
                                                <div class="col-12">
                                                    <b-form-group :label="$t('surgery_or_discomfort')">
                                                        {{
                                                            healthForm.surgeryOrDiscomfort ? healthForm.surgeryOrDiscomfort : '-'
                                                        }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-12 col-lg-4">
                                                    <b-form-group :label="$t('used_drugs')">
                                                        {{ healthForm.usedDrugs ? healthForm.usedDrugs : '-' }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-6 col-lg-4">
                                                    <b-form-group :label="$t('disability_type')">
                                                        {{
                                                            healthForm.disabilityType ? this.getParameterText(stateData.disabledTypes.items, healthForm.disabilityType) : '-'
                                                        }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-6 col-lg-4">
                                                    <b-form-group :label="$t('disability_rate')">
                                                        {{
                                                            healthForm.disabilityRate ? healthForm.disabilityRate : '-'
                                                        }}
                                                    </b-form-group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="mb-2">
                                            <h6 class="text-uppercase mb-0">{{ $t('osym_information').toUpper() }}</h6>
                                        </div>
                                        <div class="border px-5 pt-5 pb-2 mb-4 rounded">
                                            <div class="row">
                                                <div class="col-6 col-md-6 col-lg-6 col-xl-3">
                                                    <b-form-group :label="$t('program_code')">{{
                                                            stateData.osym_code
                                                        }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-6 col-md-6 col-lg-6 col-xl-3">
                                                    <b-form-group :label="$t('placement_score')">{{
                                                            stateData.placement_score
                                                        }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-6 col-md-6 col-lg-6 col-xl-3">
                                                    <b-form-group :label="$t('placement_rank')">{{
                                                            stateData.placement_rank
                                                        }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <b-form-group :label="$t('osymDocCode')">{{
                                                            osymForm.osymDocCode
                                                        }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <b-form-group :label="$t('osymExamCode')">{{
                                                            osymForm.osymExamCode
                                                        }}
                                                    </b-form-group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="mb-2">
                                            <h6 class="text-uppercase mb-0">
	                                            {{ $t('requests_info').toUpper() }}
                                            </h6>
                                        </div>
                                        <div class="border px-5 pt-5 pb-2 mb-4 rounded">
                                            <div class="row">
                                                <div class="col-6 col-lg-4">
                                                    <b-form-group :label="$t('want_leave_of_absence')">
                                                        {{
                                                            stateData.want_leave_of_absence == 'E' ? $t('yes') : $t('no')
                                                        }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-6 col-lg-4">
                                                    <b-form-group :label="$t('leave_of_absence_reason')">
                                                        {{ stateData.reason_for_leave_of_absence ? this.getParameterText(stateData.leaveOfAbsenceReasons, stateData.reason_for_leave_of_absence) : '-' }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-6 col-lg-4">
                                                    <b-form-group :label="$t('leave_of_absence_semesters')">
                                                        {{
                                                            stateData.leave_of_absence_semesters ? (this.getOptionText(this.leaveOfAbsenceDurationOptions, stateData.leave_of_absence_semesters)) : '-'
                                                        }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-6 col-lg-4">
                                                    <b-form-group :label="$t('has_english_cert')">
                                                        {{
                                                            stateData.has_english_proficiency_certificate == 'E' ? $t('yes') : $t('no')
                                                        }}
                                                    </b-form-group>
                                                </div>
                                                <div class="col-6 col-lg-4">
                                                    <b-form-group :label="$t('cert_type')">
                                                        {{
                                                            stateData.english_proficiency_certificate_type ? this.getParameterText(stateData.englishProficiencyCertificateTypes, stateData.english_proficiency_certificate_type) : '-'
                                                        }}
                                                    </b-form-group>
                                                </div>
                                                
                                                <template v-if="stateData.english_proficiency_certificate_type === 'toefl'">
                                                    <div class="col-6 col-lg-4">
                                                        <b-form-group :label="$t('toefl_username')">
                                                            {{
                                                                stateData.toefl_username ? stateData.toefl_username : '-'
                                                            }}
                                                        </b-form-group>
                                                    </div>
                                                    <div class="col-6 col-lg-4">
                                                        <b-form-group :label="$t('toefl_password')">
                                                            {{
                                                                stateData.toefl_password ? stateData.toefl_password : '-'
                                                            }}
                                                        </b-form-group>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="mb-2">
                                            <h6 class="text-uppercase mb-0">{{ $t('documents').toUpper() }}</h6>
                                        </div>
                                        <div class="border px-5 pt-5 pb-2 mb-4 rounded">
                                            <div v-for="(file,index) in documents" :key="index">
                                                <div class="file-upload-box mb-1 d-flex"
                                                     v-if="file.online_required || file.file">
                                                    <div class="filename flex-grow-1 mr-2">
                                                        {{ file.name }}
                                                    </div>
                                                    <b-button variant="outline-secondary"
                                                              class="btn-40 download"
                                                              :v-b-popover.hover.right="$t('download')"
                                                              v-if="file.file"
                                                              @click="downloadFile(file.file)">
                                                        <i class="ri-download-2-line"></i>
                                                    </b-button>
                                                    <b-button variant="outline-secondary"
                                                              class="btn-40"
                                                              :v-b-popover.hover.right="$t('download')"
                                                              v-if="!file.file">
                                                        <i class="ri-close-line"></i>
                                                    </b-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="mb-4">
                                            <h6 class="text-uppercase mb-0">
                                                {{ $t('registration_statement').toUpper() }}</h6>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <b-form-group>
                                            <div @click="openStatement1Modal">
                                                <b-form-checkbox v-model="regStatementForm.statement1"
                                                                 :disabled="!regStatementForm.statement1">
                                                    <span v-html="$t('statement_1')"
                                                          @click="openStatement1Modal"></span>
                                                </b-form-checkbox>
                                            </div>
                                        </b-form-group>
                                        <b-form-group>
                                            <div @click="openModalClick">
                                                <b-form-checkbox v-model="regStatementForm.statement2"
                                                                 :disabled="!regStatementForm.statement2">
                                                    <span v-html="$t('statement_2')" @click="openModalClick"></span>
                                                </b-form-checkbox>
                                            </div>

                                        </b-form-group>

                                        <b-form-group v-if="stateData.program_code=='42022101'">
                                            <div @click="openStatement3Modal">
                                                <b-form-checkbox v-model="regStatementForm.statement3"
                                                                 :disabled="!regStatementForm.statement3">
                                                    <span v-html="$t('statement_3')"
                                                          @click="openStatement3Modal"></span>
                                                </b-form-checkbox>
                                            </div>
                                        </b-form-group>

                                        <b-alert variant="danger" show class="mb-5" v-if="stateData.payment_status!='E'">
                                            <div>
                                                * Online kayıt sistemi {{stateData.registration_dates.end_date}} ({{stateData.registration_dates.end_date_hour}}) tarihinde kapatılacağından ödemenizi bu tarihe kadar yapmış olmanız gerekmektedir. Ödeme yapmamanız durumunda kayıt işleminiz tamamlanmayacaktır.
                                            </div>
                                        </b-alert>
                                        <b-alert variant="danger" show class="mb-5" v-if="stateData.payment_status!='E'">
                                            <div>
                                                * Online ödeme işlemlerini <a href="http://umis.bau.edu.tr/epayment/login">http://umis.bau.edu.tr/epayment/login</a> linki üzerinden yapabilirsiniz.
                                            </div>
                                        </b-alert>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between">
                <b-button v-show="tabIndex === 0" type="button" variant="outline-secondary disabled"
                          @click="tabIndex--">{{ $t('back').toUpper() }}
                </b-button>
                <b-button v-show="tabIndex > 0" type="button" variant="outline-secondary" @click="tabIndex--">
                    {{ $t('back').toUpper() }}
                </b-button>
                <div class="d-flex">
                    <a class="btn btn-outline-secondary mr-1" href="/KAYIT-KILAVUZU-24-25.pdf"
                       target="blank" download>{{ $t('registration_guide').toUpper() }}</a>
                    <a class="btn btn-outline-secondary ml-1" href="https://www.youtube.com/watch?v=acpirPvwTrc"
                       target="blank">{{ $t('help_videos').toUpper() }}</a>
                </div>
                <b-button v-if="tabIndex === 0" type="button" variant="outline-secondary" @click="tabIndex++">{{ $t('next').toUpper() }}
                </b-button>
                <b-button v-if="tabIndex === 8 && !isCompleted" type="button" variant="primary" @click="submitForm(false)">
                    {{ $t(stateData.payment_status=='E'?'complete_registration':'send').toUpper() }}
                </b-button>
                <b-button v-if="tabIndex === 6 " type="button" variant="primary" @click="requestsValid(false)">
                    {{ $t('save').toUpper() }}
                </b-button>
                <b-button v-if="tabIndex === 7 " type="button" variant="primary" @click="fileValid">
                    {{ $t('save').toUpper() }}
                </b-button>
                <b-button v-if="tabIndex === 2 " type="button" variant="primary" @click="familyValid">
                    {{ $t('save').toUpper() }}
                </b-button>
                <b-button v-if="tabIndex === 3 " type="button" variant="outline-secondary" @click="contactValid">
                    {{ $t('next').toUpper() }}
                </b-button>
                <b-button v-if="tabIndex === 4 " type="button" variant="primary" @click="healthValid">
                    {{ $t('save').toUpper() }}
                </b-button>
                <b-button v-if="tabIndex === 5 " type="button" variant="primary" @click="osymValid">
                    {{ $t('save').toUpper() }}
                </b-button>
                <b-button v-if="tabIndex === 1 " type="button" variant="primary" @click="addressValid">
                    {{ $t('save').toUpper() }}
                </b-button>
            </div>
        </div>
        <CommonModal ref="languageExams" size="xl">
            <template v-slot:CommonModalContent>
                İngilizce Hazırlık Programından muaf olabilmek için, öğrencilerin üniversitemiz tarafından eşdeğerliliği
                kabul edilen uluslararası sınavlardan birinden yeterli puanı almaları gerekmektedir. Son üç yıl
                içerisinde alınmış olan yabancı dil sınavı puanları kabul edilmektedir.
                <br/>
                <table class="table table-bordered">
                    <tr>
                        <th>Lisans ve Dikey Geçiş düşünen<br>ön lisans (MYO) öğrencileri için</th>
                        <th>İngilizce Öğretmenliği ve<br>Tıp Fakültesi öğrencileri için</th>
                        <th>Geçerlik süresi</th>
                    </tr>
                    <tr>
                        <td>TOEFL (IBT) 72*</td>
                        <td>TOEFL (IBT) 79*</td>
                        <td>3 yıl</td>
                    </tr>
                    <tr>
                        <td>Pearson PTE Akademik 55</td>
                        <td>Pearson PTE Akademik 78</td>
                        <td>3 yıl</td>
                    </tr>
                    <tr>
                        <td>CAE C</td>
                        <td>CAE A</td>
                        <td>3 yıl</td>
                    </tr>
                    <tr>
                        <td>YDS / e- YDS / YÖKDİL 60</td>
                        <td>YDS / e- YDS / YÖKDİL 80</td>
                        <td>3 yıl</td>
                    </tr>
                </table>
            </template>
        </CommonModal>
        <CommonModal ref="statementModal1">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('statement_1') }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <p>
                    MADDE 9 –
                    <br>(1) Üniversiteye kesin kayıt hakkı kazananların ilk kayıt işlemleri uygun görülen usullerle,
                    Rektörlükçe ilan edilen tarihlerde ve istenilen belgelerle yapılır. Öğrenciadayından kayıt
                    sırasında, kayıt için istenilen belgelerin aslı veya Üniversite tarafından onaylı bir örneği alınır.
                    <br>(2) Belgelerinde eksiklik ya da tahrifat olanlar Üniversiteye kayıt yaptıramazlar. Yanlış beyan
                    ve sahte belge ile kayıt yaptırmış olanların durumu ortaya çıktığında kayıtları ve bu kayıt
                    nedeniyle kazanmış oldukları tüm hakları iptal edilir ve haklarında yasal işlem başlatılır.
                    <br>(3) Kayıt için zamanında başvurmayan ve gerekli belgeleri sağlamayan öğrenciler kayıt haklarını
                    kaybederler. Ancak, mazeretleri sebebiyle süresi içerisinde kayıt yaptıramayanların mazeretlerinin
                    kabulüne ve kayıtlarının yapılıp yapılamayacağına Rektörlük tarafından karar verilir.
                    <br>(4) Kayıt tarihinden sonra YÖK tarafından kendisine kayıt hakkı verilenlerin kayıtları,
                    yukarıdaki fıkralarda açıklanan koşullarda yapılır.
                </p>
                <b-button variant="primary" type="button" @click="checkStatement1">{{$t('read_and_accept')}}</b-button>
            </template>
        </CommonModal>
        <CommonModal ref="statementModal3">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('statement_3').toUpper() }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <p>
                    <b>Sağlık durumuma ve adli sicil kaydıma ilişkin bilgilerimin</b>, Bahçeşehir Üniversitesi
                    tarafından Kişisel Verilerin İşlenmesine Dair Aydınlatma Metni kapsamında, sağlık durumunun
                    kontrolü, sağlık durumunun bölüm yeterliliğini karşılayıp karşılamadığının tespiti, faaliyetlerin
                    mevzuata uygun yürütülmesi ve yetkili kişi, kurum ve kuruluşlara bilgi verilmesi amaçları ile
                    işlenmesine ve aynı amaçlarla yetkili kurum ve kuruluşlar ile paylaşılmasına rıza veriyorum.
                </p>
                <b-button variant="primary" type="button" @click="checkStatement3">{{$t('read_and_accept')}}</b-button>
            </template>
        </CommonModal>
        <CommonModal ref="statementModal" size="xl">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('clarification_text') }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div class="center">
                    <div class="content">
                        <div class="mb-2">
                            <h2>Kişisel Verilerin İşlenmesine Dair Aydınlatma Metni – Ön Lisans, Lisans Öğrencileri</h2>
                        </div>
                        <div class="text-left">
                            <div>Bu metin, Bahçeşehir Üniversitesi’nin (“BAU”) öğrenci kişisel verilerini nasıl işlediği hakkında sizleri bilgilendirmeyi
                                amaçlamaktadır. Kişisel verilerinizin işlenmesine ilişkin konular, aşağıda başlıklar halinde sıralanmıştır. </div>
                            <br>
                            <div class='mb-2'><b>Kişisel verilerimin işlenmesinden kim sorumlu?</b></div>
                            Bahçeşehir Üniversitesi olarak, öğrencilerimizin kişisel verilerinin nasıl işleneceğini belirliyor ve veri kayıt sisteminin yönetimini sağlıyoruz. Bir diğer ifadeyle, kişisel verilerinizi işlerken veri sorumlusu olarak hareket ediyoruz.
                            <br><br>
                            <div class='mb-2'><b>Kişisel verilerim nasıl toplanıyor?</b></div>
                            Kişisel verilerinizi aşağıdaki kanallar ve yöntemler aracılığıyla topluyoruz:<br><br>
                            − <b>Kişisel verilerinizi toplama kanalları </b>  ➢ Fiziki ortamlarda form doldurmanız veya bilgilerinizi
                            bizimle paylaşmanız; öğrenci bilgi sistemlerine bilgi girişi yapmanız; internet sitesi ya da dijital
                            ortamlarda yer alan iletişim formlarını doldurmanız; telefon, e-posta, sosyal medya hesapları
                            veya  BAU  whatsapp hattı üzerinden bizimle iletişime geçmeniz halinde belirtilen kanallar
                            aracılığıyla kişisel verileriniz toplanmaktadır. <br><br>
                            − <b>Kişisel verilerinizi toplama yöntemleri</b>  ➢ Kişisel verileriniz, kısmen otomatik,  otomatik  veya
                            veri kayıt sisteminin parçası olmak kaydı ile otomatik olmayan yöntemler ile toplanmaktadır.<br>
                            <br>
                            <div class='mb-2'><b>Hangi kişisel verilerim işleniyor?</b></div>
                            BAU olarak adayların aşağıda belirtilen kişisel verilerini yurtiçinde veya yurtdışında işliyoruz:  <br>
                            <div class='mt-2'>
                                <table class="table">
                                    <tr>
                                        <th>Kimlik</th>
                                        <th>İletişim</th>
                                        <th>Eğitim</th>
                                        <th>Diğer Bilgileriniz</th>
                                    </tr>
                                    <tr>
                                        <td>Ad-soyad, kimlik numarası, cinsiyet, uyruk, doğum yeri ve tarihi, kimlik fotokopisi, öğrenci numarası, uluslararası öğrencilerimizin pasaport bilgileri</td>
                                        <td>Telefon numarası, eposta adresi, iş ve ev adresi</td>
                                        <td>Fakülte, bölüm, sınıf, staj bilgileri, danışman bilgisi, proje, ödev, sunum, tez vb. eğitim materyalleri, bursluluk durumu, sınav sonuçları, not ortalaması, başarı durumu, devamsızlık durumu, transkript, alınan dersler, hazırlık okuma bilgisi, ÖSYM yerleşme ve puan bilgileri, yatay geçiş bilgisi, yan dal/çift ana dal bilgisi, kredi bilgileri, mezun olunan okul bilgileri</td>
                                        <td>Fotoğraf, meslek bilgisi, banka bilgileri, öğrenci işlem kayıtları, IBAN, gelir bilgisi, disiplin işlemleri bilgileri, işlem güvenliği bilgileri, imza, mazeret nedeni, ayrılma/kayıt dondurma nedeni, kayıt, mezuniyet ve ayrılma tarihleri, yakınlarınızın kimlik ve iletişim bilgileri, anket çalışmaları neticesinde elde edilen bilgiler.</td>
                                    </tr>
                                </table>
                            </div>
                            Pilotaj bölümüne kayıt yaptırmak isteyen öğrencilerimizin, mazeret nedeniyle sağlık raporu sunan öğrencilerimizin ve engelli öğrencilerimizin öğrencilerimizin <b>ayrıca sağlık verilerini</b> de işliyoruz. Pilotaj bölümüne kayıt yaptırmak isteyen öğrencilerimizin <b>adli sicil kaydına ilişkin bilgilerini</b> de işliyoruz.  <br><br>
                            <div class='mb-2'><b>Kişisel verilerim hangi amaçlarla ve hangi hukuki sebeplere dayalı olarak işleniyor?</b></div>
                            Kişisel Verilerin Korunması Kanunu’nun (\"Kanun”) 5. maddesi, kişisel verilerin işlenmesine ilişkin hukuki
                            sebepleri düzenlemektedir.  Kişisel verilerinizi  bu madde kapsamında, aşağıda belirtilen amaçlar  ve
                            hukuki sebeplere uygun olarak la yurtiçinde veya yurtdışında işliyoruz: <br><br>
                            <hr>
                            <div><b>Sözleşmenin kurulması veya ifası</b></div>
                            <div>
                                Öğrenci tarafından sunulan bilgi ve belgelerin doğruluğunun teyit edilmesi, Öğrenci kayıt sözleşmesinin imzalanması; ön lisans, lisans programları kayıt, nakil ve kayıt dondurma/iptal süreçlerinin yürütülmesi, Öğrenci bilgilerinin BAU tarafından kullanılan kurumsal kayıt sistemlerine aktarılması, Öğrencilere ilişkin duyuru ve bilgilendirmelerin yapılması, Öğrenci okul kartlarının basılması ve teslim edilmesi, BAU Öğrenci portal kaydı oluşturulması; öğrencilere kurumsal eposta hesap ve şifresinin tanımlanması ve iletilmesi, Öğrenci danışman atama süreçlerinin yürütülmesi, Ders seçme/dersten çekilme işlemlerinin gerçekleştirilmesi, Öğrenci devamsızlık durumu takibinin yapılması ve sistemlere girilmesi, Sistemlere ders notu girişlerinin yapılması, Ölçme ve değerlendirme süreçlerinin yürütülmesi, Öğrencilerin mazeret başvurularının değerlendirilmesi ve cevaplanması, Öğrenci zorunlu-gönüllü staj işlemlerinin yürütülmesi, Tez ve proje danışmanlık faaliyetlerinin yürütülmesi, Öğrencilerin mezuniyet ve ilişik kesme işlemlerinin tamamlanması, Öğrenci belgesi, transkript, geçici mezuniyet belgesi, diploma, disiplin, ilişik kesme belgesi düzenlenmesi ve teslim edilmesi, <br><br><hr><br> <br>
                            </div>
                            <div class='col-4 d-flex align-items-center'><b>Kişisel veri işlemenin meşru menfaatlerimiz için zorunlu olması</b></div>
                            <div>
                                Organizasyon, fuar, yarışma ve etkinliklerin düzenlenmesi, Öğrenciler ile sınav, ders dönemi başlangıcı ve etkinlikler de dahil olmak üzere iletişim süreçlerinin yürütülmesi, Bilimsel araştırma, yayın ve danışmanlık faaliyetlerinin sürdürülmesi, Öğrencilerimizin yetenek ve kariyer gelişimlerine yönelik faaliyetlerin organize edilmesi, Öneri, talep ve şikayetlerin alınması ve sonuçlandırılması, Faaliyetlerimizin incelenmesi, denetlenmesi ve raporlanması süreçlerinin yürütülmesi, Faaliyetlerimizin iyileştirilmesine ilişkin çalışmaların yürütülmesi; bu kapsamda anket çalışmalarının yapılması, Kampüslerimizin ve operasyonlarımızın güvenliğinin sağlanmasına ilişkin süreçlerin yürütülmesi; bu kapsamda, öğrencinin kampüs alanına giriş ve çıkışlarının kontrol edilmesi, Finans ve muhasebe süreçlerinin yürütülmesi,<br><br><hr><br>
                            </div>
                            <div class='col-4 d-flex align-items-center'><b>Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması</b></div>
                            <div>
                                Öğrencilerin kayıt yenileme, yaz okulu, çift anadal programı, erasmus programı, yatay/dikey geçiş, lisansüstü, yüksek lisans, burs ve
                                benzeri öğrencilikle ilgili işlemlerin yürütülmesi, Olası bir uyuşmazlık durumunda haklarımızın korunabilmesi, kullanılabilmesi ve yasal
                                süreçlerin yürütülmesi.<br><br><hr><br>
                            </div>
                            <div class='col-4 d-flex align-items-center'><b>Veri işlemenin Kanunlarda açıkça öngörülmesi ve hukuki yükümlülüklerimizin yerine getirilmesi</b></div>
                            <div>
                                Disiplin işlemleri ve hukuk işlerinin takibi ve yürütülmesi, Yükseköğretim Kurulu başta olmak üzere yetkili kurum veya kuruluşların
                                Üniversitemizden talepte bulunması ya da bu kurumlara bildirim yapmamızın öngörüldüğü durumlarda, yasal yükümlülüklerimizin yerine
                                getirilmesi. mevzuattan kaynaklı yükümlülüklerimizin yerine getirilmesi, öğrencinin pilotaj bölümüne kayıt için uygunluğunun tespit
                                edilmesi ve yetkili kurum ve kuruluşlara bilgi verilmesi amaçlarıyla işlenecektir<br><br>
                            </div>
                            <div class="page-number">2/3</div>
                            <div><b>İlgili kişinin açık rızasının bulunması</b></div>
                            <div>
                                Pilotaj bölümüne kayıt yaptırmak isteyen öğrencilerin sağlık verileri, sağlık durumunun kontrolü, sağlık durumunun bölüm yeterliliğini
                                karşılayıp karşılamadığının tespiti, faaliyetlerin mevzuata uygun yürütülmesi ve yetkili kişi, kurum ve kuruluşlara bilgi verilmesi
                                amaçlarıyla işlenecektir.<br><br><hr><br>
                            </div>
                            <div><b>Üniversite Kayıt İşlemleri</b></div>
                            <div>Üniversite kayıt, nakil ve kayıt dondurma/iptal süreçlerinin yürütülmesi, oryantasyon aktivitelerinin gerçekleştirilmesi <br><br><hr><br>
                            </div>
                            <div><b>Eğitim ve Öğretim Faaliyetleri</b></div>
                            <div>
                                Eğitim ve öğretim faaliyetlerinin yürütülmesi, ders seçme/dersten çekilme işlemlerinin gerçekleştirilmesi, öğrencilerimizin yetenek ve
                                kariyer gelişimlerine yönelik aktivitelerin yürütülmesi, öğrencilerin akademik ve sosyal gelişimlerinin takibi, ölçme ve değerlendirme
                                süreçlerinin yürütülmesi, zorunlu-gönüllü staj işlemlerinin yürütülmesi.<br><br><hr><br>
                            </div>
                            <div><b>Organizasyon ve Etkinlikler</b></div>
                            <div>
                                Organizasyon, fuar, yarışma ve etkinliklerin 4 düzenlenmesi, öğrenci etkinliklerinin yürütülmesi, kurumsal iletişim süreçlerinin yürütülmesi<br><br><hr><br>
                            </div>
                            <div><b>Diğer Faaliyetlerimizdeki Amaçlar</b></div>
                            <div>
                                İletişim süreçlerinin yürütülmesi; öğrenciler için duyuru ve bilgilendirmelerin yapılması, dijital uygulama kullanıcı hesaplarının
                                oluşturulması ve yönetimi, talep ve şikayetlerin alınması ve sonuçlandırılması, anket çalışmalarının yürütülmesi, faaliyetlerimizin
                                denetlenmesi ve raporlanması, disiplin ve hukuk işlerinin takibi ve yürütülmesi, faaliyetlerimizin mevzuata uygun olarak yürütülmesi,
                                yetkili kişi, kurum ya da kuruluşlara bilgi verilmesi, finans ve muhasebe süreçlerinin yürütülmesi.<br><br><hr><br>
                            </div>
                            <div class='mb-2'><b>	Kişisel verilerim başkalarına aktarılıyor mu?</b></div>
                            Kişisel verilerinizi yurtiçi veya yurtdışındaki üçüncü kişilere aktarıyoruz. Kişisel verilerinizi kimlere, hangi amaçlarla aktardığımız aşağıda belirtilmektedir: <br>
                            <ul>
                                <li>Faaliyetlerimizin mevzuata uygun olarak yürütülmesi, yetkili kişi, kurum veya kuruluşlara bilgi verilmesi, hukuki yükümlülüklerimizin
                                    yerine getirilmesi → Yüksek Öğretim Kurumu ve Diğer Yetkili Kişi, Kurum veya Kuruluşlar.</li>
                                <li>Üçüncü kişilerden mal veya hizmet tedariki kapsamında destek alınması, operasyonlarımızın
                                    devamlılığının sağlanması, finans ve muhasebe işlerinin yürütülmesi → Tedarikçiler.  </li>
                            </ul>
                            Kişisel verileriniz, veli bilgilendirme beyan formu ile yetkilendirmeniz durumunda, formda belirttiğiniz
                            kişi/kişiler ile de paylaşılacaktır<br>
                            <div class='mb-2'><b>Kişisel verilerimi işlerken dayanılan hukuki sebep ve veri işleme faaliyeti örnekleri</b></div>
                            Kişisel verilerinizi işlerken dayandığımız hukuki sebepler, aşağıda örnek veri işleme faaliyetleri ile
                            açıklanmıştır:
                            <br>
                            <br>
                            <br>
                            <b>- Örneğin, öğrenci kayıt sözleşmesinin imzalanması için bilgilerinizin kullanılması.</b> <br>
                            Sözleşmenin kurulması veya ifası
                            <br><br>
                            <b>- Örneğin, ders seçme/dersten çekilmeişlemlerinin yerine getirilmesi.</b>  <br>
                            Bir hakkın tesisi,kullanılması veya korunması
                            <br><br>
                            <b>- Örneğin, üniversite, fakülte ya da öğrencietkinlikleri hakkında duyuruların yapılması.</b>  <br>
                            Meşru menfaatlerimiz için zorunlu olması
                            <br><br>
                            <b>- Örneğin, Yüksek Öğretim Kurumu denetimleri kapsamında talep edildiğinde bilgilerinizin sunulması.</b>  <br>
                            Hukuki yükümlülüklerimiz
                            <br><br>
                            <div class='mb-2'><b>Kişisel veri sahibi olarak haklarımı nasıl kullanabilirim?</b></div>
                            Kişisel Verilerin Korunması Kanunu’nun 11. maddesinde kişisel veri sahibi ilgili kişilere tanınan bazı
                            haklar  bulunmaktadır. Bu haklarınızı kullanmak için  Veri Sorumlusuna Başvuru Usul ve Esasları
                            Hakkında  Tebliğ’de  (kısaca  “Tebliğ”)  bazı  usuller  belirlenmiştir.  Haklarınızı  kullanmak  isterseniz,
                            aşağıdaki yöntemlerden biri ile, ya da Tebliğ’de belirtilen diğer yöntemlerle talebinizi bize iletebilirsiniz:
                            <ul>
                                <li>İnternet sitesi aracılığıyla → <a href='https://bau.edu.tr/'>bau.edu.tr</a> adresindeki İlgili Kişi Başvuru Formu’nu doldurarak</li>
                                <li>Posta  yoluyla  →  Çırağan Cad. Osmanpaşa Mektebi Sok. No: 4 –  6,  34353,  Beşiktaş/İstanbul posta göndererek.</li>
                                <li>E-posta yoluyla1 → BAU İrtibat Kişisi’nin <a href='mailto:kvkk@bau.edu.tr'>kvkk@bau.edu.tr</a> adresine e-posta göndererek.</li>
                            </ul>
                            <br>
                            <div class='mb-2'><b>Detaylı bilgiye nasıl ulaşabilirim?</b></div>
                            BAU’nun kişisel verileri nasıl işlediği hakkında detaylı bilgi almak için; <br>
                            <ul>
                                <li><a href='https://bau.edu.tr/'>bau.edu.tr</a> adresindeki “Kişisel Verilerin İşlenmesi ve Korunması Politikası”nı inceleyebilir,</li>
                                <li>BAU İrtibat Kişisi’nin <a href='mailto:kvkk@bau.edu.tr'>kvkk@bau.edu.tr</a> adresine e-posta gönderebilirsiniz.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <b-button variant="primary" type="button" @click="checkStatement2">{{$t('read_and_accept')}}</b-button>
            </template>
        </CommonModal>
        <CommonModal ref="smsVerifyModal">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('verification') }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <div class="form-group">{{ $t('write_verify_code') }}</div>
                    <div>
                        <b-alert :variant="alertVariant" :show="alertShow"><span
                            v-if="alertVariant == 'success'">{{ $t('verify_code_again_not') }}</span> <span
                            v-if="alertVariant == 'danger'">{{ $t('send_again_not') }}</span></b-alert>
                        <ValidationObserver ref="smsVerifyActionForm">
                            <div class="mb-3">
                                <ValidationProvider name="smsVerifyCode" rules="required" v-slot="{valid, errors}">
                                    <b-form-group>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <label class="col-form-label">{{ $t('verification_code') }}</label>
                                            <div class="text-muted"><i
                                                class="ri-time-line top-plus-2 mr-1"></i>{{ timerCount }} sn
                                            </div>
                                        </div>
                                        <b-form-input
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            v-model="smsVerifyActionForm.verifyCode"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="d-flex justify-content-between">
                                <b-button type="button" variant="primary" @click="approvalSmsVerify">{{
                                        $t('verify')
                                    }}
                                </b-button>
                                <b-button type="button" variant="outline-secondary" @click="EnterCodeAgainFunc()"
                                          v-show="smsSendAgainButtonStatus">{{ $t('send_again_btn') }}
                                </b-button>
                            </div>
                        </ValidationObserver>
                    </div>
                </div>
            </template>
        </CommonModal>
        <CommonModal ref="payment">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('not_have_payment_approval') }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <p>
                    Kayıt işleminiz için gerekli bilgileri güncellediniz ve belgelerinizi yüklediniz. Kayıt işleminizi tamamlamak için, ödeme yapmanız gerekmektedir.
                </p>
                <p>
                    Ödemeyi <b><a href="/epayment" target="_blank">online ödeme</a></b> sistemimiz üzerinden veya banka havalesi/EFT yoluyla yapabilirsiniz.
                </p>
                <p>
                    Ödemeniz onaylandıktan sonra kaydınız tamamlanacaktır ve SMS gönderilecektir.
                </p>
            </template>
        </CommonModal>
    </div>
</template>

<script>
import IdentityInformation from "@/modules/onlineRegistration/pages/tab/IdentityInformation"
import Address from "@/modules/onlineRegistration/pages/tab/Address"
import Communication from "@/modules/onlineRegistration/pages/tab/Communication"
import Documents from "@/modules/onlineRegistration/pages/tab/Documents"
import Summary from "@/modules/onlineRegistration/pages/tab/Documents"
import OnlineRegistrationService from "@/services/OnlineRegistrationService";
import DistrictOutlineSelectbox from "@/components/interactive-fields/DistrictOutlineSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";

import CommonModal from "@/components/elements/CommonModal.vue";
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
    name: "onlineRegistrationIndex",
    components: {
        IdentityInformation,
        Address,
        Communication,
        Documents,
        CommonModal,
        ValidationProvider,
        ValidationObserver,
        DistrictOutlineSelectbox,
    },
    data() {
        return {
            size: 'md',
            selected: '',
            show: true,
            file1: null,
            tabIndex: 1,
            stateData: null,
            healthForm: {
                surgeryOrDiscomfort: null,
                usedDrugs: null,
                disabilityType: null,
                disabilityRate: 0
            },
            emergencyForm: {
                degreeOfProximity: null,
                nameSurname: null,
                gsmNo: null,
            },
            disabledOptions: null,
            upperAlphaMask: {
                mask: 'UUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUU',
                tokens: {
                    'U': {
                        pattern: /[A-Za-z ÇĞİÖŞÜçğıöşü]/,
                        transform: function (v) {
                            v = v.replaceAll('i', 'İ');
                            return v.toUpper();
                        }
                    }
                }
            },
            upperAlphaNumMask: {
                mask: 'UUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUU',
                tokens: {
                    'U': {
                        pattern: /[A-Za-z0-9 ÇĞİÖŞÜçğıöşü]/,
                        transform: function (v) {
                            v = v.replaceAll('i', 'İ');
                            return v.toUpper();
                        }
                    }
                }
            },

            regStatementForm: {
                statement1: false,
                statement2: false,
                statement3: false,
            },

            osymForm: {
                osymDocCode: null,
                osymExamCode: null
            },

            smsVerifyForm: {
                gsmNo: null
            },

            smsSendButtonStatus: true,
            smsSendAgainButtonStatus: false,

            smsVerifyActionForm: {
                verifyCode: null
            },

            alertShow: true,
            alertVariant: "success",
            timerEnabled: false,
            timerCount: 5,
            forgotPass: false,
            documents: null,

            cities: null,
            contactForm: {
                city: null,
                district: null,
                address: null
            },

            familyForm: {
                mothersJob: null,
                mothersGsm: null,
                fathersJob: null,
                fathersGsm: null,
                mothersJobOther: null,
                fathersJobOther: null
            },
            jobs: null,
            leaveOfAbsenceReasonOptions: null,
            leaveOfAbsenceDurationOptions: null,

            requestsForm: {
                wantLeave: null,
                hasEnglish: null,
                reason: null,
                duration: null,
                certificate: null,
                toeflUsername: null,
                toeflPassword: null
            },
            certificateOptions: null,
            isCompleted:false,
            errStateMobileVerify:false,
            errStateEmailVerify:false
        }
    },
    watch: {
        tabIndex: async function (newValue) {
            if (newValue == 8) {
                const isValidAddress = await this.addressValid(true);
                if (!isValidAddress) {
                    this.tabIndex = 1;
                    return;
                }
                // else if (this.tabIndex == 1) {
                //     this.tabIndex = 2
                // }else if (this.tabIndex == 2) {
                //     this.tabIndex = 3
                // }

                const isValidFamily = await this.$refs.familyForm.validate();
                if (!isValidFamily) {
                    this.tabIndex = 2;
                    return;
                }

                const isValidContact = await this.contactValid(true);
                if (!isValidContact) {
                    this.tabIndex = 3;
                    return;
                }

                const isValidEmergency = await this.$refs.emergencyForm.validate();
                if (!isValidEmergency) {
                    this.tabIndex = 3;
                    return;
                }

                const isValidHealth = await this.healthValid(true);
                if (!isValidHealth) {
                    this.tabIndex = 4;
                    return;
                }

                const isValidOsym = await this.osymValid(true);
                if (!isValidOsym) {
                    this.tabIndex = 5;
                    return;
                }

                let fileCheck = true;
                Object.keys(this.documents).forEach(key => {
                    let item = this.documents[key];
                    if (item.online_required === true && item.file == null) {
                        fileCheck = false;
                    }
                });
                //const isValidFile = await this.fileValid(true);

                if (!fileCheck) {
                    this.tabIndex = 7
                    return;
                }

                if (this.stateData.want_leave_of_absence == null) {
                    this.$toast.error(this.$t('want_leave_of_absence_missing'));
                    this.tabIndex = 6
                    return;
                }

                if (this.stateData.program_language === 'TR' && this.stateData.want_optional_prep == null) {
                    this.$toast.error(this.$t('want_optional_prep_missing'));
                    this.tabIndex = 6
                    return;
                }

                if (this.stateData.program_language === 'EN' && this.stateData.has_english_proficiency_certificate == null) {
                    this.$toast.error(this.$t('do_you_have_language_proficiency_missing'));
                    this.tabIndex = 6
                    return;
                }

                OnlineRegistrationService.getFiles({token: this.stateData.token})
                    .then(response => {
                        const data = response.data.data;
                        Object.keys(data).forEach(key => {
                            this.documents[key].file = data[key];
                        });
                    });
            }

        },
        'stateData.has_english_proficiency_certificate'(newValue) {
            Object.keys(this.stateData.fileTypes).forEach(key => {
                let item = this.stateData.fileTypes[key];
                if (item.code === 'language_proficiency') {
                    this.stateData.fileTypes[key].online_required = (newValue === 'E');
                    this.stateData.fileTypes[key].hide = (newValue !== 'E');
                }
            });
            this.$refs.documents.$forceUpdate();
        },

        'stateData.want_leave_of_absence'(newValue) {
            Object.keys(this.stateData.fileTypes).forEach(key => {
                let item = this.stateData.fileTypes[key];
                if(item.code === 'leave_of_absence') {
                    this.stateData.fileTypes[key].online_required = (newValue === 'E')
                    this.stateData.fileTypes[key].hide = (newValue !== 'E')
                }
            })
            this.$refs.documents.$forceUpdate();
        },

        timerEnabled(value) {
            if (value) {
                this.timerCount--
            }
        },
        timerCount: {
            handler(value) {

                if (value > 0 && this.timerEnabled) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                } else if (value > 0 && !this.timerEnabled) {
                    this.alertShow = false
                    this.smsSendAgainButtonStatus = false
                } else {
                    this.alertVariant = "danger"
                    this.alertShow = true
                    this.smsSendAgainButtonStatus = true
                }

            },
            immediate: true,
        },

        'requestsForm.wantLeave'(val){
            if(val == 'H'){
                this.requestsForm.reason = null
                this.requestsForm.duration = null
                this.requestsForm.explanation_for_leave_of_absence = null
            }
        },

        'requestsForm.hasEnglish'(val){
            if(val == 'H'){
                this.requestsForm.certificate = null
                this.requestsForm.toeflUsername = null
                this.requestsForm.toeflPassword = null
                this.requestsForm.english_proficiency_exam_date = null
                this.requestsForm.english_proficiency_exam_score = null

            }
        }

    },

    methods: {
        async contactValid(onlyReturn) {
            if (typeof onlyReturn == 'undefined') {
                onlyReturn = false;
            }
            let mobileVerify = this.$store.getters['onlineRegistration/getMobileVerify']
            let emailVerify = this.$store.getters['onlineRegistration/getEmailVerify']

            if (!mobileVerify && !this.stateData.sms_approval_date) {
                this.$toast.error(this.$t('mobile_verify_not'));
                this.errStateMobileVerify = true
            } else if (!emailVerify && !this.stateData.email_approval_date) {
                this.$toast.error(this.$t('email_verify_not'));
                this.errStateEmailVerify = true
            } else {
               
                if (onlyReturn === true) {
                    return true;
                }
                const isValid = await this.emergencyValid(false);
                if (!isValid) {
                    return;
                }
                this.tabIndex++
                this.errStateMobileVerify = false
                this.errStateEmailVerify = false
                return;
            }
            if (onlyReturn === true) {
                return false;
            }
        },

        async healthValid(onlyReturn) {
            if (typeof onlyReturn == 'undefined') {
                onlyReturn = false;
            }
            const isValid = await this.$refs.healthForm.validate();
            if (isValid) {
                if (onlyReturn === true) {
                    return true;
                }
                await this.submitForm(true);
                this.tabIndex++
            } else {
                if (onlyReturn === true) {
                    return false;
                }
                this.$toast.error(this.$t('fields_are_reqired'));
            }
        },

        async emergencyValid(onlyReturn) {

            const isValid = await this.$refs.emergencyForm.validate();
            if (isValid) {
                if (onlyReturn === true) {
                    return true;
                }
                this.submitForm(true);
            } else {
                if (onlyReturn === true) {
                    return false;
                }
                this.$toast.error(this.$t('fields_are_reqired'));
            }
            return isValid;
        },

        async osymValid(onlyReturn) {
            if (typeof onlyReturn == 'undefined') {
                onlyReturn = false;
            }
            const isValidOsym = await this.$refs.osymForm.validate();
            if (isValidOsym) {
                if (onlyReturn === true) {
                    return true;
                }
                await this.submitForm(true);
                this.tabIndex++
            } else {
                if (onlyReturn === true) {
                    return false;
                }
                this.$toast.error(this.$t('fields_are_reqired'));
            }
        },

        async familyValid (onlyReturn){
            if (typeof onlyReturn == 'undefined') {
                onlyReturn = false;
            }

            const isValidFamily = await this.$refs.familyForm.validate();
            if (isValidFamily) {
                if (onlyReturn === true) {
                    return true;
                }
                await this.submitForm(true);
                this.stateData.mothers_job = this.familyForm.mothersJob
                this.stateData.mothers_phone = this.familyForm.mothersGsm
                this.stateData.fathers_job = this.familyForm.fathersJob
                this.stateData.fathers_phone = this.familyForm.fathersGsm
                this.tabIndex++
            } else {
                if (onlyReturn === true) {
                    return false;
                }
                this.$toast.error(this.$t('fields_are_reqired'));
            }

        },

        async requestsValid (onlyReturn){
            if (typeof onlyReturn == 'undefined') {
                onlyReturn = false;
            }
            let isValidRequests = await this.$refs.requestsForm.validate();
            if(!this.requestsForm.wantLeave){
                isValidRequests=false;
                this.$refs.requestsForm.errors.wantLeave.push(this.$t('this_field_is_required'));
            }
            if(this.stateData.program_language=='TR' && !this.stateData.want_optional_prep){
                isValidRequests=false;
                this.$refs.requestsForm.errors.want_optional_prep.push(this.$t('this_field_is_required'));
            }
            if(this.stateData.program_language=='EN' && !this.requestsForm.hasEnglish){
                isValidRequests=false;
                this.$refs.requestsForm.errors.hasEnglish.push(this.$t('this_field_is_required'));
            }
            if (isValidRequests) {
                await this.submitForm(true);
                this.stateData.want_leave_of_absence = this.requestsForm.wantLeave
                this.stateData.english_proficiency_exam_date = this.requestsForm.english_proficiency_exam_date
                this.stateData.english_proficiency_exam_score = this.requestsForm.english_proficiency_exam_score
                this.stateData.reason_for_leave_of_absence = this.requestsForm.reason
                this.stateData.explanation_for_leave_of_absence = this.requestsForm.explanation_for_leave_of_absence
                this.stateData.leave_of_absence_semesters = this.requestsForm.duration
                this.stateData.english_proficiency_certificate_type = this.requestsForm.certificate
                this.stateData.toefl_username = this.requestsForm.toeflUsername
                this.stateData.toefl_password = this.requestsForm.toeflPassword
                this.tabIndex++
            } else {
                if (onlyReturn === true) {
                    return false;
                }

                this.$toast.error(this.$t('fields_are_reqired'));
            }

        },

        async addressValid(onlyReturn) {
            if (typeof onlyReturn == 'undefined') {
                onlyReturn = false;
            }
            const isValidAddress = await this.$refs.contactForm.validate();
            if (isValidAddress) {
                if (onlyReturn === true) {
                    return true;
                }

                await this.submitForm(true);
                this.stateData.contact_city_id = this.contactForm.city;
                this.stateData.contact_district_id = this.contactForm.district;
                this.stateData.contact_address = this.contactForm.address;
                this.tabIndex++
            } else {
                if (onlyReturn === true) {
                    return false;
                }
                this.$toast.error(this.$t('fields_are_reqired'));
            }
        },

        async fileValid(onlyReturn) {
            if (typeof onlyReturn == 'undefined') {
                onlyReturn = false;
            }
            await OnlineRegistrationService.getFiles({token: this.stateData.token})
                .then(response => {
                    const files = response.data.data;
                    let check = true
                    Object.keys(this.stateData.fileTypes).forEach(key => {
                        if (this.stateData.fileTypes[key].online_required == true) {
                            if (files[key] == null) {
                                check = false
                            }
                        }
                    });
                    if (check) {
                        if (onlyReturn === true) {
                            return true;
                        }
                        this.tabIndex++
                    } else {
                        if (onlyReturn === true) {
                            return false;
                        }
                        this.$toast.error(this.$t('fields_are_reqired'));
                    }
                })


        },

        openModalClick() {
            this.$refs.statementModal.$refs.commonModal.show()
        },

        openLanguageExamsModal() {
            this.$refs.languageExams.$refs.commonModal.show()
        },

        openStatement1Modal() {
            this.$refs.statementModal1.$refs.commonModal.show()
        },

        checkStatement1() {
            this.regStatementForm.statement1 = true
            this.$refs.statementModal1.$refs.commonModal.hide()
        },

        openStatement3Modal() {
            this.$refs.statementModal3.$refs.commonModal.show()
        },

        checkStatement3() {
            this.regStatementForm.statement3 = true
            this.$refs.statementModal3.$refs.commonModal.hide()
        },


        checkStatement2() {
            this.regStatementForm.statement2 = true
            this.$refs.statementModal.$refs.commonModal.hide()
        },

        getSelectText(ref) {
            if (ref == undefined || !ref.value) {
                return;
            }
            let found = null;
            ref.options.forEach((item) => {
                if (item.value == ref.value) {
                    found = item.text;
                }
            });

            return found;
        },

        disabledSelectOptions() {
            this.disabledOptions = [
                {value: null, text: this.$t('select')}
            ]
            this.stateData.disabledTypes.items.forEach((value, index) => {
                let name = this.$i18n.locale == 'tr' ? value.name : value.name_en
                this.disabledOptions.push({value: value.code, text: name})
            })
        },

        async submitForm(onlySave) {
            if (typeof onlySave == 'undefined') {
                onlySave = false;
            }
            let isValid = true;
            let isValidOsym = true;
            let isValidContact = true;
            let isValidFamily = true;
            let isValidRequests = true;
            if (!onlySave) {
                isValid = await this.$refs.healthForm.validate();
                isValidOsym = await this.$refs.osymForm.validate();
                isValidContact = await this.$refs.contactForm.validate();
                isValidFamily = await this.$refs.familyForm.validate();
                isValidRequests = await this.$refs.requestsForm.validate();
            }

            let mobileVerify = this.$store.getters['onlineRegistration/getMobileVerify'] || this.stateData.sms_approval_date != null
            let emailVerify = this.$store.getters['onlineRegistration/getEmailVerify'] || this.stateData.email_approval_date != null

            if (onlySave === true || (isValid && isValidOsym && isValidContact &&
                this.regStatementForm.statement1 && this.regStatementForm.statement2 && this.regStatementForm.statement3
                && mobileVerify && emailVerify && isValidFamily && isValidRequests)) {

                let formData = {
                    token: this.stateData.token,
                    emergency_person_relation: this.emergencyForm.degreeOfProximity,
                    emergency_person_name: this.emergencyForm.nameSurname,
                    emergency_person_phone: this.emergencyForm.gsmNo,
                    surgery_illness_history: this.healthForm.surgeryOrDiscomfort,
                    medicines: this.healthForm.usedDrugs,
                    type_of_disability: this.healthForm.disabilityType,
                    disability_rate: this.healthForm.disabilityRate,
                    // hes_code: this.healthForm.hesCode,
                    placement_result_document_code: this.osymForm.osymDocCode,
                    exam_result_document_code: this.osymForm.osymExamCode,
                    contact_address: this.contactForm.address,
                    contact_city_id: this.contactForm.city,
                    contact_district_id: this.contactForm.district,
                    want_leave_of_absence: this.requestsForm.wantLeave,
                    want_optional_prep: this.stateData.want_optional_prep,
                    has_english_proficiency_certificate: this.requestsForm.hasEnglish,
                    english_proficiency_exam_date: this.requestsForm.english_proficiency_exam_date,
                    english_proficiency_exam_score: this.requestsForm.english_proficiency_exam_score,
                    is_information_correct: this.regStatementForm.statement1 == true ? 1 : '',
                    is_kvkk_approved: this.regStatementForm.statement2 == true ? 1 : '',
                    mothers_phone: this.familyForm.mothersGsm,
                    fathers_phone: this.familyForm.fathersGsm,
                    mothers_job: this.familyForm.mothersJob == 'Diğer' ? this.familyForm.mothersJobOther : this.familyForm.mothersJob,
                    fathers_job: this.familyForm.fathersJob == 'Diğer' ? this.familyForm.fathersJobOther : this.familyForm.fathersJob,
                    reason_for_leave_of_absence: this.requestsForm.reason,
                    leave_of_absence_semesters: this.requestsForm.duration,
                    english_proficiency_certificate_type: this.requestsForm.certificate,
                    toefl_username: this.requestsForm.toeflUsername,
                    toefl_password: this.requestsForm.toeflPassword
                }

                OnlineRegistrationService.storeRegistration(formData)
                    .then(response => {
                        this.stateData = Object.assign(this.stateData, formData);
                        this.$store.dispatch("onlineRegistration/restoreRegistration", this.stateData);
                        if (onlySave === false) {
                            this.sendApprovalSms()
                        }
                    })
                    .catch(e => {
                        if (e.status == '404' || e.status == '406') { ///406 geldiği zaman değiştirilecek

                            if (e.data.message) {
                                this.$refs.emergencyForm.errors.degreeOfProximity.push(e.data.message);
                                this.$toast.error(this.$t('api.' + e.data.message));
                            }
                        } else {
                            if (e.data.errors.emergency_person_relation) {
                                this.$refs.emergencyForm.errors.degreeOfProximity.push(e.data.errors.emergency_person_relation[0]);
                            }
                            if (e.data.errors.emergency_person_name) {
                                this.$refs.emergencyForm.errors.nameSurname.push(e.data.errors.emergency_person_name[0]);
                            }
                            if (e.data.errors.emergency_person_phone) {
                                this.$refs.emergencyForm.errors.gsmNo.push(e.data.errors.emergency_person_phone[0]);
                            }
                            if (e.data.errors.surgery_illness_history) {
                                this.$refs.healthForm.errors.surgeryOrDiscomfort.push(e.data.errors.surgery_illness_history[0]);
                            }
                            if (e.data.errors.medicines) {
                                this.$refs.healthForm.errors.usedDrugs.push(e.data.errors.medicines[0]);
                            }
                            if (e.data.errors.type_of_disability) {
                                this.$refs.healthForm.errors.disabilityType.push(e.data.errors.type_of_disability[0]);
                            }
                            // if (e.data.errors.hes_code) {
                            //     this.$refs.healthForm.errors.hesCode.push(e.data.errors.hes_code[0]);
                            // }
                            if (e.data.errors.placement_result_document_code) {
                                this.$refs.osymForm.errors.osymDocCode.push(e.data.errors.placement_result_document_code[0]);
                            }
                            if (e.data.errors.exam_result_document_code) {
                                this.$refs.osymForm.errors.osymExamCode.push(e.data.errors.exam_result_document_code[0]);
                            }

                            if (e.data.errors.mothers_job) {
                                this.$refs.familyForm.errors.mothersJob.push(e.data.errors.mothers_job[0]);
                            }
                            if (e.data.errors.mothers_phone) {
                                this.$refs.familyForm.errors.mothersGsm.push(e.data.errors.mothers_phone[0]);
                            }
                            if (e.data.errors.fathers_job) {
                                this.$refs.familyForm.errors.fathersJob.push(e.data.errors.fathers_job[0]);
                            }
                            if (e.data.errors.fathers_phone) {
                                this.$refs.familyForm.errors.fathersGsm.push(e.data.errors.fathers_phone[0]);
                            }

                            if (e.data.errors.reason_for_leave_of_absence) {
                                this.$refs.requestsForm.errors.leaveOfAbsenceReason.push(e.data.errors.reason_for_leave_of_absence[0]);
                            }
                            if (e.data.errors.leave_of_absence_semesters) {
                                this.$refs.requestsForm.errors.leaveOfAbsenceDuration.push(e.data.errors.leave_of_absence_semesters[0]);
                            }
                            if (e.data.errors.english_proficiency_certificate_type) {
                                this.$refs.requestsForm.errors.englishCertType.push(e.data.errors.english_proficiency_certificate_type[0]);
                            }
                            if (e.data.errors.toefl_username) {
                                this.$refs.requestsForm.errors.toeflUsername.push(e.data.errors.toefl_username[0]);
                            }
                            if (e.data.errors.toefl_password) {
                                this.$refs.requestsForm.errors.toeflPassword.push(e.data.errors.toefl_password[0]);
                            }
                        }
                        this.tabIndex = 4
                    })
            } else if (!mobileVerify) {
                this.$toast.error(this.$t('mobile_verify_not'));
                this.tabIndex = 3
            } else if (!emailVerify) {
                this.$toast.error(this.$t('email_verify_not'));
                this.tabIndex = 3
            } else if (!this.regStatementForm.statement1) {
                this.$toast.error(this.$t('statement1_error'));
            } else if (!this.regStatementForm.statement2) {
                this.$toast.error(this.$t('statement2_error'));
            } else if (!this.regStatementForm.statement3) {
                this.$toast.error(this.$t('statement3_error'));
            } else if (!isValidContact) {
                this.tabIndex = 1
            } else if (!isValidOsym) {
                this.tabIndex = 5
            } else if (!isValidRequests) {
                this.tabIndex = 6
            }  else {
                this.tabIndex = 1
            }
        },
        sendApprovalSms() {
            OnlineRegistrationService.sendApprovalSms({token: this.stateData.token})
                .then(response => {

                    this.$refs.smsVerifyModal.$refs.commonModal.show()
                    this.forgotPass = true
                    this.timerCount = response.data.data.expires_in
                    this.timerEnabled = true
                    this.smsSendAgainButtonStatus = false
                })
                .catch(e => {
                    this.$toast.error(this.$t('api.' + e.data.message));
                })
        },
        EnterCodeAgainFunc() {
            this.alertVariant = "success"
            this.smsVerifyActionForm.verifyCode = null
            this.sendApprovalSms()
        },
        async approvalSmsVerify() {
            const isValid = await this.$refs.smsVerifyActionForm.validate();
            if (isValid) {
                const isValid = await this.$refs.smsVerifyActionForm.validate();
                if (isValid) {
                    let smsData = {
                        token: this.stateData.token,
                        approval_code: this.smsVerifyActionForm.verifyCode
                    }
                    OnlineRegistrationService.approve(smsData).then(response => {
                        this.$store.dispatch("onlineRegistration/logout");
                        this.$router.push('/online/registration/success')
                    }).catch(e => {
                        if(e.data.message=='PAYMENT_STATUS_INVALID'){
                            this.isCompleted=true;
                            this.$refs.payment.$refs.commonModal.show();
                        }
                        else if (e.status == '406') { ///406 geldiği zaman değiştirilecek
                            if (e.data.message) {
                                this.$refs.smsVerifyActionForm.errors.smsVerifyCode.push(this.$t('api.' + e.data.message));
                            }
                        } else {
                            if (e.data.errors.approval_code) {
                                this.$refs.smsVerifyActionForm.errors.smsVerifyCode.push(e.data.errors.approval_code[0]);
                            }
                            if (e.data.errors.token) {
                                this.$refs.smsVerifyActionForm.errors.smsVerifyCode.push(e.data.errors.token[0]);
                            }
                        }
                    })
                }
            }
        },
        downloadFile(uuid) {
            OnlineRegistrationService.downloadFile({token: this.stateData.token, uuid: uuid})
                .then(response => {
                    const blob = new Blob([response.data], {type: response.headers['content-type']})
                    const objectUrl = window.URL.createObjectURL(blob)
                    window.open(objectUrl)
                })
                .catch(error => {
                    if (error.status == 422) {
                        this.$toast.error(error.data.errors.file[0]);
                    }
                    if (error.status == 406) {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    }
                });
        },

        logout() {
            this.$store.dispatch("onlineRegistration/logout");
            this.$router.push('/online/registration/login')
        },
        cityOptions() {
            this.cities = [
                {value: null, text: this.$t('select')}
            ]
            this.stateData.cities.forEach((value, index) => {
                this.cities.push({value: value.id, text: value.name})
            })
        },
        jobOptions() {
            this.jobs = [
                {value: null, text: this.$t('select')}
            ]
            var otherMother=true;
            var otherFather=true;
            this.stateData.jobs.forEach((value, index) => {
                this.jobs.push({value: value.code, text: value.name})
                if(this.stateData.mothers_job && this.stateData.mothers_job==value.code){
                    otherMother=false;
                }
                if(this.stateData.fathers_job && this.stateData.fathers_job==value.code){
                    otherFather=false;
                }
            })
            if(otherMother && this.stateData.mothers_job){
                this.familyForm.mothersJobOther=this.stateData.mothers_job;
                this.familyForm.mothersJob='Diğer';
            }
            if(otherFather && this.stateData.fathers_job){
                this.familyForm.fathersJobOther=this.stateData.fathers_job;
                this.familyForm.fathersJob='Diğer';
            }
        },
        reasonOptions() {
            this.leaveOfAbsenceReasonOptions = [
                {value: null, text: this.$t('select')}
            ]

            this.stateData.leaveOfAbsenceReasons.forEach((value, index) => {
                this.leaveOfAbsenceReasonOptions.push({value: value.code, text: value.name})
            })
        },
        durationOptions() {
            this.leaveOfAbsenceDurationOptions = [
                {value: null, text: this.$t('select')},
            ]
            if(this.stateData.leaveOfAbsenceFall){
                this.leaveOfAbsenceDurationOptions.push({value: 1, text: this.$t('fall')});
                if(this.stateData.leaveOfAbsenceSpring){
                    this.leaveOfAbsenceDurationOptions.push({value: 3, text: this.$t('fall_and_spring')});
                }
            } else if(this.stateData.leaveOfAbsenceSpring){
                this.leaveOfAbsenceDurationOptions.push({value: 2, text: this.$t('spring')});
            }
        },
        certOptions() {
            this.certificateOptions = [
                {value: null, text: this.$t('select')}
            ]

            this.stateData.englishProficiencyCertificateTypes.forEach((value, index) => {
                this.certificateOptions.push({value: value.code, text: value.name})
            })
        }
    },
    created() {
        this.$store.dispatch("onlineRegistration/initAuth");
        this.stateData = {
            ...this.$store.getters['onlineRegistration/getRegistration'],
            ...this.$store.getters['onlineRegistration/getOrToken'],
            fileTypes: {
                ...this.$store.getters['onlineRegistration/getFileTypes']
            },
            mobileVerify: this.$store.getters['onlineRegistration/getMobileVerify'],
            emailVerify: this.$store.getters['onlineRegistration/getEmailVerify'],
            disabledTypes: this.$store.getters['onlineRegistration/getDisabledTypes'],
            cities: this.$store.getters['onlineRegistration/getCities'],
            jobs: this.$store.getters['onlineRegistration/getJobs'],
            englishProficiencyCertificateTypes: this.$store.getters['onlineRegistration/getEnglishProficiencyCertificateTypes'],
            leaveOfAbsenceReasons: this.$store.getters['onlineRegistration/getLeaveOfAbsenceReasons'],
            leaveOfAbsenceFall: this.$store.getters['onlineRegistration/getLeaveOfAbsenceFall'],
            leaveOfAbsenceSpring: this.$store.getters['onlineRegistration/getLeaveOfAbsenceSpring'],
        }

        if (!this.stateData.type_of_disability) {
            this.stateData.type_of_disability = '0';
        }
        this.contactForm.city = this.stateData.contact_city_id;
        this.contactForm.district = this.stateData.contact_district_id;
        this.contactForm.address = this.stateData.contact_address;
        this.documents = this.stateData.fileTypes;

        this.emergencyForm.nameSurname = this.stateData.emergency_person_name;
        this.emergencyForm.gsmNo = this.stateData.emergency_person_phone;
        this.emergencyForm.degreeOfProximity = this.stateData.emergency_person_relation;
        // this.healthForm.hesCode = this.stateData.hes_code;
        this.healthForm.usedDrugs = this.stateData.medicines;
        this.healthForm.surgeryOrDiscomfort = this.stateData.surgery_illness_history;
        this.healthForm.disabilityType = this.stateData.type_of_disability;
        this.healthForm.disabilityRate = this.stateData.disability_rate;
        this.osymForm.osymDocCode = this.stateData.placement_result_document_code;
        this.osymForm.osymExamCode = this.stateData.exam_result_document_code;
        this.healthForm.disability_rate=this.stateData.disability_rate;

        this.familyForm.mothersJob = this.stateData.mothers_job;
        this.familyForm.mothersGsm = this.stateData.mothers_phone;
        this.familyForm.fathersJob = this.stateData.fathers_job;
        this.familyForm.fathersGsm = this.stateData.fathers_phone;

        this.requestsForm.wantLeave = this.stateData.want_leave_of_absence;
        this.requestsForm.hasEnglish = this.stateData.has_english_proficiency_certificate;
        this.requestsForm.english_proficiency_exam_date = this.stateData.english_proficiency_exam_date;
        this.requestsForm.english_proficiency_exam_score = this.stateData.english_proficiency_exam_score;
        this.requestsForm.reason = this.stateData.reason_for_leave_of_absence;
        this.requestsForm.duration = this.stateData.leave_of_absence_semesters;
        this.requestsForm.certificate = this.stateData.english_proficiency_certificate_type;
        this.requestsForm.toeflUsername = this.stateData.toefl_username;
        this.requestsForm.toeflPassword = this.stateData.toefl_password;

        Object.keys(this.stateData.fileTypes).forEach((key) => {
            this.stateData.fileTypes[key].hide=false;
        });

        if(this.stateData.program_code!='42022101'){
            this.regStatementForm.statement3=true;
        }
        this.disabledSelectOptions()
        this.cityOptions()
        this.jobOptions()
        this.reasonOptions()
        this.durationOptions()
        this.certOptions()
    },
    mounted() {
        this.$refs.district.getDistricts();
    }
}
</script>
