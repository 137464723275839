<template>
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="mb-2">
                <h6 class="text-uppercase mb-0">{{ $t('osym_address_information').toUpper() }}</h6>
            </div>
        </div>
        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3">
            <b-form-group :label="$t('city')">
                {{ stateData.address_city_name ? stateData.address_city_name : '-' }}
            </b-form-group>
        </div>
        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3">
            <b-form-group :label="$t('district')">
                {{ stateData.address_district ? stateData.address_district : '-' }}
            </b-form-group>
        </div>
        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3">
            <b-form-group :label="$t('neighborhood')">
                -
            </b-form-group>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
            <b-form-group :label="$t('address_2')">
                {{ stateData.address ? stateData.address : '-' }}
            </b-form-group>
        </div>
    </div>
</template>
<script>
import DistrictOutlineService from "@/services/DistrictOutlineService";

export default {
    props: {
        stateData: {
            type: Object,
            default: null
        },
        formData: {
            type: Object,
            default: null
        },
        tab: {
            type: Number,
            default: null
        }
    },

    data() {
        return {
            options: []
        }
    },

    methods: {
        getDistricts(cityId) {
            if (cityId) {
                DistrictOutlineService.getDistrict(cityId)
                    .then(response => {
                        const data = response.data.data;
                        data.map(item => {
                            this.options.push({
                                value: item.id,
                                text: item.name,
                            });
                        });
                    });
            }
        }
    },

    computed: {
        compCity: function () {
            const ret = [];
            if (this.formData.city) {
                this.stateData.cities.forEach((val, key) => {
                    if (val.id == this.formData.city) {
                        this.getDistricts(this.formData.city)
                        ret.push(val.name)
                    }
                })
                return ret
            } else {
                return ['-'];
            }
        },

        tabComp: function () {
            return this.tab
        },

        districtComp: function () {
            const ret = [];
            this.options.forEach((val) => {
                if (val.value == this.formData.district) {
                    ret.push(val.text)
                }
            })
            if (ret) {
                return ret
            } else {
                return ['-']
            }
        }
    }

}
</script>
